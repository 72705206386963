.out {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    width: 100% !important;
    height: 100%;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-name: bg;
    .logo {
        // animation: rotate 2s linear infinite;
        margin-bottom: 3rem;
    }
    h2 {
        font-family: 'Karla' !important;
        text-transform: uppercase !important;
    }
    p {
        font-family: 'Merriweather';
        font-size: 1rem;
    }
    .loginButton {
        color: #000;
        position: absolute;
        right: 2rem;
        top: 2rem;
        width: 2rem;
        height: 2rem;
        &:hover {
            border: 1px dotted #abdd91;
            cursor: pointer;
        }
    }
    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    } 

    @keyframes bg {
        0% {
            background: #E15A5A;
        }
        20% {
            background: #F5CACB;
        }
        40% {
            background: #FBE4CB;
        }
        60% {
            background: #D8EECF;
        }
        80% {
            background: #abdd91;
        }
        100% {
            background: #6AAE46;
        }
      }
}